/**
 * @file 公共函数、公共变量
 * @author  zhangxianlong<zhangxianlong@qike366.com>
 * @date 2021-9-29
 */
/**
 * @file 取代px的单位函数
 * @example:
    width: .rem(120)
*/
/* 
 * 统一添加后缀，解决代码规范问题
 * example: 
    font-family+: PingFangSC-Regular, PingFang SC;
    .fixFontFamily();
*/
/* Document
   ========================================================================== */
/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in iOS.
 */
html {
  line-height: 24px;
  /* 基准字体 12px  行高 * 2 = 24px */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}
/* Sections
     ========================================================================== */
/**
   * Remove the margin in all browsers.
   */
/**
   * Render the `main` element consistently in IE.
   */
main {
  display: block;
}
/**
   * Correct the font size and margin on `h1` elements within `section` and
   * `article` contexts in Chrome, Firefox, and Safari.
   */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
/* Grouping content
     ========================================================================== */
/**
   * 1. Add the correct box sizing in Firefox.
   * 2. Show the overflow in Edge and IE.
   */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}
/**
   * 1. Correct the inheritance and scaling of font size in all browsers.
   * 2. Correct the odd `em` font sizing in all browsers.
   */
pre {
  font-family: monospace, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  /* 1 */
  font-size: 1em;
  /* 2 */
}
/* Text-level semantics
     ========================================================================== */
/**
   * Remove the gray background on active links in IE 10.
   */
/**
   * 1. Remove the bottom border in Chrome 57-
   * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
   */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}
/**
   * Add the correct font weight in Chrome, Edge, and Safari.
   */
b,
strong {
  font-weight: bolder;
}
body {
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  overflow: hidden;
  height: 100%;
}
body span {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}
/**
   * 1. Correct the inheritance and scaling of font size in all browsers.
   * 2. Correct the odd `em` font sizing in all browsers.
   */
code,
kbd,
samp {
  font-family: monospace, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  /* 1 */
  font-size: 1em;
  /* 2 */
}
/**
   * Add the correct font size in all browsers.
   */
small {
  font-size: 80%;
}
/**
   * Prevent `sub` and `sup` elements from affecting the line height in
   * all browsers.
   */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
/* Embedded content
     ========================================================================== */
/**
   * Remove the border on images inside links in IE 10.
   */
/* Forms
     ========================================================================== */
/**
   * 1. Change the font styles in all browsers.
   * 2. Remove the margin in Firefox and Safari.
   */
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}
/**
   * Show the overflow in IE.
   * 1. Show the overflow in Edge.
   */
button,
input {
  /* 1 */
  overflow: visible;
}
/**
   * Remove the inheritance of text transform in Edge, Firefox, and IE.
   * 1. Remove the inheritance of text transform in Firefox.
   */
button,
select {
  /* 1 */
  text-transform: none;
}
/**
   * Correct the inability to style clickable types in iOS and Safari.
   */
button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}
/**
   * Remove the inner border and padding in Firefox.
   */
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
/**
   * Restore the focus styles unset by the previous rule.
   */
button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted #4B74FF;
}
/**
   * Correct the padding in Firefox.
   */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
/**
   * 1. Correct the text wrapping in Edge and IE.
   * 2. Correct the color inheritance from `fieldset` elements in IE.
   * 3. Remove the padding so developers are not caught out when they zero out
   *    `fieldset` elements in all browsers.
   */
legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */
}
/**
   * Add the correct vertical alignment in Chrome, Firefox, and Opera.
   */
progress {
  vertical-align: baseline;
}
/**
   * Remove the default vertical scrollbar in IE 10+.
   */
textarea {
  overflow: auto;
}
/**
   * 1. Add the correct box sizing in IE 10.
   * 2. Remove the padding in IE 10.
   */
[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}
/**
   * Correct the cursor style of increment and decrement buttons in Chrome.
   */
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}
/**
   * 1. Correct the odd appearance in Chrome and Safari.
   * 2. Correct the outline style in Safari.
   */
[type='search'] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}
/**
   * Remove the inner padding in Chrome and Safari on macOS.
   */
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
/**
   * 1. Correct the inability to style clickable types in iOS and Safari.
   * 2. Change font properties to `inherit` in Safari.
   */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}
/* Interactive
     ========================================================================== */
/*
   * Add the correct display in Edge, IE 10+, and Firefox.
   */
details {
  display: block;
}
/*
   * Add the correct display in all browsers.
   */
summary {
  display: list-item;
}
/* Misc
     ========================================================================== */
/**
   * Add the correct display in IE 10+.
   */
template {
  display: none;
}
/**
   * Add the correct display in IE 10.
   */
[hidden] {
  display: none;
}
::selection {
  color: #333;
}
/**
    * 常用标签初始化
    */
* {
  margin: 0;
  padding: 0;
  list-style: none;
}
HTML,
body,
div,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
dl,
li,
dt,
dd,
p,
blockquote,
pre,
form,
fieldset,
table,
th,
td {
  border: none;
  margin: 0px;
  padding: 0px;
}
address,
caption,
cite,
code,
dfn,
em,
strong,
th {
  font-style: normal;
  font-weight: normal;
}
a {
  text-decoration: none;
  background-color: transparent;
}
a:link {
  color: #4B74FF;
}
a:visited {
  color: #4B74FF;
}
a:hover {
  color: #4B74FF;
}
a:active {
  color: #4B74FF;
}
input::-ms-clear {
  display: none;
}
input::-ms-reveal {
  display: none;
}
input {
  -webkit-appearance: none;
  margin: 0;
  outline: none;
  padding: 0;
}
input::-webkit-input-placeholder {
  color: #bbb;
}
input::-ms-input-placeholder {
  color: #bbb;
}
input::-moz-placeholder {
  color: #bbb;
}
input[type='submit'],
input[type='button'] {
  cursor: pointer;
}
button[disabled],
input[disabled] {
  cursor: default;
}
img {
  border: none;
  border-style: none;
}
ul,
ol,
li {
  list-style-type: none;
}
dl,
ol,
ul {
  margin-bottom: 1em;
}
:root:root {
  --adm-color-primary: #4B74FE;
}
.ant-select-selector,
.ant-select-selection-placeholder {
  font-size: .24rem;
}
.ant-select-dropdown {
  width: 100%!important;
  left: 0!important;
  text-align: center;
}
.ant-select-dropdown .ant-select-item {
  height: 0.8rem;
  line-height: 0.8rem;
  padding: 0;
  font-size: 0.28rem;
  text-align: center;
  margin: 0;
  border-top: 0.01rem solid #d9d9d9;
}
.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #4B74FE;
  background-color: #fff;
}
#__vconsole {
  position: absolute;
  z-index: 9999999;
}
#root {
  height: 100%;
}
.app,
.app__content {
  height: 100%;
}
.app-conent-box {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.app-conent-box .routes {
  height: 100%;
}
.app__layout-main .no-view {
  display: none;
}
.app__layout-main .routes {
  height: 100%;
}
.app__header {
  height: 50px;
  padding: 0 0 0 30px;
}
.app__header-userzone {
  position: absolute;
  height: 50px;
  line-height: 50px;
  width: 225px;
  top: 0;
  right: 0;
}
.app__header-userzone .anticon {
  font-size: 17px;
  margin-right: 30px;
}
.app__header-userzone .ant-avatar {
  margin-top: -6px;
}
.app__header-userzone .user-link {
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  right: 0;
}
.app__header-userzone .user-link a {
  color: #fff;
  display: flex;
  align-items: center;
}
.app__header-userzone .user-link .ant-avatar {
  margin-right: 10px;
}
.app__logo {
  color: white;
  display: flex;
  align-items: center;
}
.app__logo .tit {
  font-size: 14px;
  margin-left: 10px;
}
.app__logo .vip {
  font-size: 18px;
  display: flex;
  width: 28px;
  height: 14px;
  margin-left: 10px;
}
.app__logo-icon {
  overflow: hidden;
  height: 50px;
  float: left;
  padding-right: 60px;
}
.app__logo-icon .anticon,
.app__logo-icon svg {
  height: 50px;
}
.ant-layout-sider {
  background-color: white;
}
.ant-menu-title-content,
.ant-menu-submenu-title {
  color: #000;
}
.ant-menu-title-content:hover,
.ant-menu-submenu-title:hover {
  color: #fff;
}
.ant-menu-item-selected .ant-menu-title-content,
.ant-menu-item-active .ant-menu-title-content,
.ant-menu-item-selected .ant-menu-submenu-title,
.ant-menu-item-active .ant-menu-submenu-title,
.ant-menu-item-selected .ant-menu-item-icon,
.ant-menu-item-active .ant-menu-item-icon {
  color: #fff;
}
.ant-layout-sider-children {
  height: calc(2vh);
  overflow-y: auto;
  overflow-x: hidden;
}
.ant-layout-sider-children::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
.ant-layout-sider-children::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: 4px;
}
.ant-layout-sider-children::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.06);
  border-radius: 3px;
}
.app__layout-collapse {
  position: absolute;
  bottom: 0;
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  z-index: 9;
  font-size: 18px;
}
.vip__popover {
  left: 212px !important;
  top: 52px !important;
}
.vip__popover .ant-popover .vip__popover .ant-popover-placement-bottom {
  padding-top: 10px;
  left: 212px!important;
  top: 56px!important;
}
.vip__popover .ant-popover-arrow {
  display: none;
}
.vip__popover .ant-popover-inner-content {
  padding: 0;
}
.popover__icon {
  font-size: 60px;
  border-radius: 30px;
  margin-left: 16px;
}
.vip__text {
  margin-left: 16px;
  color: #FFFFFF;
  width: calc(84%);
  margin-bottom: 12px;
}
.am-tab-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
}
.overall-situation-back-top {
  bottom: 1.5rem !important;
  width: 1rem !important;
  height: 1rem !important;
  border-radius: 50%;
  text-align: center;
  background-image: repeating-linear-gradient(#6CCCFF, #4B74FF);
}
.overall-situation-back-top .iconpark-icon {
  width: 0.5rem;
  height: 0.5rem;
  margin-top: 0.25rem;
}
