.tar-bars {
  border: 1px solid #D9D9D9;
  position: fixed;
  bottom: 0;
  z-index: 99999;
  width: 100%;
}
.tar-bars .tar-bars-box {
  width: 100%;
  max-width: 600px;
  display: flex;
  justify-content: space-between;
  background: #FFF;
  padding-bottom: 0.1rem;
  padding-top: 0.13rem;
}
.tar-bars .tar-bars-box .tar-bars-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}
.tar-bars .tar-bars-box .tar-bars-main .anticon {
  font-size: 0.40rem;
}
.tar-bars .tar-bars-box .tar-bars-main .tar-bars-title {
  font-size: 0.22rem;
  font-weight: 400;
}
.tar-bars .tar-bars-box .tar-bars-main-ligh {
  color: #4B74FF;
}
.tar-bars .tar-bars-box .tar-bars-icon .iconpark-icon {
  width: 0.4rem;
  height: 0.4rem;
}
.tar-bars .tar-bars-box .tar-bars-icon .tar-bars-icon-high {
  display: inline-block;
}
